import Map from "./Map";
import React from "react";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <>
        <Map />
      </>
    );
  };
}

export default App;
